import React, { useState, useEffect } from 'react';
import styles from '../styles/ConversationLibrary.module.css';
import { AgentOutput } from '../types';

interface ConversationLibraryProps {
    show: boolean;
    walletAddress: string;
    onSelectConversation: (conversationId: string, messages: any[]) => void;
    onClose?: () => void;
    agentOutputs: AgentOutput[];
    onViewOutput: (outputContent: string) => void;
    refreshTrigger?: number;  // Add this new prop
  }

interface Conversation {
    id: number;
    conversation_id: string;
    name: string;
    created_at: string;
    last_updated: string;
}

interface ConfirmDialogProps {
    isOpen: boolean;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}



const ConversationLibrary: React.FC<ConversationLibraryProps> = ({ 
    show, 
    walletAddress, 
    onSelectConversation, 
    onClose, 
    agentOutputs, 
    onViewOutput,
    refreshTrigger  // Add this
  }) => {
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [renamingId, setRenamingId] = useState<string | null>(null);
    const [renamingOutputId, setRenamingOutputId] = useState<string | null>(null);
    const [newName, setNewName] = useState('');
    const [isExpanded, setIsExpanded] = useState<boolean>(true);
    const [viewingOutput, setViewingOutput] = useState<string | null>(null);
    const [localAgentOutputs, setLocalAgentOutputs] = useState<AgentOutput[]>([]);
    const [selectedAgentOutput, setSelectedAgentOutput] = useState<string | null>(null);
    const [newOutputName, setNewOutputName] = useState('');
    const [confirmOutputDialog, setConfirmOutputDialog] = useState({
        isOpen: false,
        outputId: '',
        message: ''
    });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        conversationId: '',
        message: ''
    });

    const API_URL = process.env.REACT_APP_API_URL || 'https://api.argothepuppy.org';

    const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ isOpen, message, onConfirm, onCancel }) => {
        if (!isOpen) return null;

        return (
            <div className={styles.confirmOverlay}>
                <div className={styles.confirmDialog}>
                    <p>{message}</p>
                    <div className={styles.confirmButtons}>
                        <button onClick={onConfirm} className={styles.confirmButton}>Yes</button>
                        <button onClick={onCancel} className={styles.cancelButton}>No</button>
                    </div>
                </div>
            </div>
        );
    };

    // Fetch Conversations on Component Load
    useEffect(() => {
        if (show && walletAddress) {
          fetchConversations();
          fetchAgentOutputs();
        }
      }, [show, walletAddress, refreshTrigger]); 

    // Fetch Agent Outputs
    const fetchAgentOutputs = async () => {
        try {
            const response = await fetch(`${API_URL}/api/agent-outputs/${walletAddress}`);
            if (response.ok) {
                const data = await response.json();
                console.log("Fetched agent outputs:", data);  // Add this log
                setLocalAgentOutputs(data);
            } else {
                console.error('Failed to fetch agent outputs:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching agent outputs:', error);
        }
    };


    const handleSetSelectedAgentOutput = (outputContent: string) => {
        console.log("Setting selectedAgentOutput:", outputContent);  // Add this line
        setSelectedAgentOutput(outputContent);
    };

    const handleViewOutput = (outputContent: string) => {
        console.log("View button clicked. Output content:", outputContent);
        onViewOutput(outputContent);  // Use onViewOutput in the correct scope
    };

    const fetchConversations = async () => {
        if (!walletAddress) {
            console.log('No wallet address provided for fetching conversations');
            return;
        }

        try {
            console.log('Fetching conversations for wallet:', walletAddress);
            const response = await fetch(`${API_URL}/api/conversations/${walletAddress}`);

            if (!response.ok) {
                throw new Error(`Failed to fetch conversations: ${response.status}`);
            }

            const data = await response.json();
            console.log('Fetched conversations:', data);

            const sortedConversations = data.sort((a: Conversation, b: Conversation) =>
                new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            );

            setConversations(sortedConversations);
        } catch (error) {
            console.error('Error fetching conversations:', error);
        }
    };

    const handleRenameOutput = async (outputId: string) => {
        if (!newOutputName.trim()) {
            setRenamingOutputId(null);
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/agent-outputs/${outputId}/rename`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: newOutputName,
                    wallet_address: walletAddress
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to rename agent output: ${response.status}`);
            }

            await fetchAgentOutputs();
        } catch (error) {
            console.error('Error renaming agent output:', error);
        }

        setRenamingOutputId(null);
        setNewOutputName('');
    };

    const handleDeleteOutput = async (outputId: string) => {
        try {
            const response = await fetch(`${API_URL}/api/agent-outputs/${outputId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ wallet_address: walletAddress })
            });

            if (!response.ok) {
                throw new Error(`Failed to delete agent output: ${response.status}`);
            }

            await fetchAgentOutputs();
        } catch (error) {
            console.error('Error deleting agent output:', error);
        }
    };

    const handleRename = async (conversationId: string) => {
        if (!newName.trim()) {
            setRenamingId(null);
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/conversations/${conversationId}/rename`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: newName,
                    wallet_address: walletAddress
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to rename conversation: ${response.status}`);
            }

            await fetchConversations();
        } catch (error) {
            console.error('Error renaming conversation:', error);
        }

        setRenamingId(null);
        setNewName('');
    };

    const handleDelete = async (conversationId: string) => {
        try {
            console.log('Attempting to delete conversation:', conversationId);
            const response = await fetch(`${API_URL}/api/conversations/${conversationId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ wallet_address: walletAddress })
            });

            if (!response.ok) {
                throw new Error(`Failed to delete conversation: ${response.status}`);
            }

            await fetchConversations();
        } catch (error) {
            console.error('Error deleting conversation:', error);
        }
    };

    const handleAgentOutputClick = (outputId: string) => {
        const selectedOutput = localAgentOutputs.find(output => output.id === outputId);

        if (selectedOutput) {
            console.log('Output sent to view:', selectedOutput.output);

            // Clear the regular conversation to ensure only the agent output displays
            onSelectConversation("", []);  // This clears the regular conversation
            setSelectedAgentOutput(selectedOutput.output);  // Set agent output specifically

            // Display the selected agent output content
            onViewOutput(selectedOutput.output);
        } else {
            console.error('Agent output not found');
        }
    };


    const handleConversationClick = async (conversationId: string) => {
        try {
            console.log('Loading conversation:', conversationId);
            const response = await fetch(`${API_URL}/api/messages/${conversationId}?wallet_address=${walletAddress}`);

            if (!response.ok) {
                throw new Error(`Failed to load conversation: ${response.status}`);
            }

            const messages = await response.json();
            console.log('Loaded messages:', messages);

            // Clear agent output to ensure only the regular conversation displays
            setSelectedAgentOutput(null);

            // Load the selected regular conversation
            onSelectConversation(conversationId, messages);
        } catch (error) {
            console.error('Error loading conversation:', error);
        }
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await fetch(`${API_URL}/api/conversations/${confirmDialog.conversationId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ wallet_address: walletAddress })
            });

            if (!response.ok) {
                throw new Error(`Failed to delete conversation: ${response.status}`);
            }

            await fetchConversations();
        } catch (error) {
            console.error('Error deleting conversation:', error);
        }
        setConfirmDialog({ isOpen: false, conversationId: '', message: '' });
    };

    const createNewConversation = async () => {
        try {
            const response = await fetch(`${API_URL}/api/conversations`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    wallet_address: walletAddress,
                    conversation_id: Date.now().toString(),
                    name: 'New Conversation'
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to create conversation: ${response.status}`);
            }

            const data = await response.json();
            await fetchConversations();
            return data.conversation_id;
        } catch (error) {
            console.error('Error creating conversation:', error);
            return null;
        }
    };

    if (!show) return null;

    return (
        <div
            className={`${styles.libraryContainer} ${
                isExpanded ? styles.expanded : styles.collapsed
            }`}
        >
            <button
                className={styles.toggleButton}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                {isExpanded ? 'CLOSE' : '←'}
            </button>
    
            {isExpanded && (
                <div className={styles.libraryContent}>
                    {/* Header */}
                    <div className={styles.header}>
                        <h2 className={styles.title}>Conversation History</h2>
                    </div>
    
                    {/* Conversation List */}
                    <div className={styles.conversationList}>
                        {conversations.length === 0 ? (
                            <div className={styles.emptyState}>
                                No conversations yet. Start a new chat!
                            </div>
                        ) : (
                            conversations.map((conversation) => (
                                <div
                                    key={conversation.conversation_id}
                                    className={styles.conversationItem}
                                >
                                    {renamingId === conversation.conversation_id ? (
                                        <div className={styles.renameInput}>
                                            <input
                                                type="text"
                                                value={newName}
                                                onChange={(e) =>
                                                    setNewName(e.target.value)
                                                }
                                                placeholder="New name"
                                                autoFocus
                                            />
                                            <button
                                                onClick={() =>
                                                    handleRename(
                                                        conversation.conversation_id
                                                    )
                                                }
                                            >
                                                ✓
                                            </button>
                                            <button
                                                onClick={() =>
                                                    setRenamingId(null)
                                                }
                                            >
                                                ✕
                                            </button>
                                        </div>
                                    ) : (
                                        <div className={styles.conversationContent}>
                                            <span
                                                className={
                                                    styles.conversationName
                                                }
                                                onClick={() =>
                                                    handleConversationClick(
                                                        conversation.conversation_id
                                                    )
                                                }
                                            >
                                                {conversation.name}
                                            </span>
                                            <div
                                                className={
                                                    styles.conversationActions
                                                }
                                            >
                                                <button
                                                    onClick={() => {
                                                        setRenamingId(
                                                            conversation.conversation_id
                                                        );
                                                        setNewName(
                                                            conversation.name
                                                        );
                                                    }}
                                                    className={
                                                        styles.renameButton
                                                    }
                                                >
                                                    ✎
                                                </button>
                                                <button
                                                    onClick={() =>
                                                        handleDelete(
                                                            conversation.conversation_id
                                                        )
                                                    }
                                                    className={
                                                        styles.deleteButton
                                                    }
                                                >
                                                    ×
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))
                        )}
                    </div>
    
                    {/* Agent Outputs Section */}
                    <div className={styles.agentOutputsSection}>
                        {/* Header */}
                        <div className={styles.header}>
                            <h2 className={styles.title}>Agent Outputs</h2>
                        </div>
    
                        {/* Agent Outputs List */}
                        <div className={styles.conversationList}>
                            {localAgentOutputs && localAgentOutputs.length > 0 ? (
                                localAgentOutputs.map((output) => (
                                    <div
                                        key={output.id}
                                        className={styles.conversationItem}
                                    >
                                        {renamingOutputId === output.id ? (
                                            <div className={styles.renameInput}>
                                                <input
                                                    type="text"
                                                    value={newOutputName}
                                                    onChange={(e) =>
                                                        setNewOutputName(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="New name"
                                                    autoFocus
                                                />
                                                <button
                                                    onClick={() =>
                                                        handleRenameOutput(
                                                            output.id
                                                        )
                                                    }
                                                >
                                                    ✓
                                                </button>
                                                <button
                                                    onClick={() =>
                                                        setRenamingOutputId(null)
                                                    }
                                                >
                                                    ✕
                                                </button>
                                            </div>
                                        ) : (
                                            <div
                                                className={
                                                    styles.conversationContent
                                                }
                                            >
                                                <span
                                                    className={
                                                        styles.conversationName
                                                    }
                                                    onClick={() =>
                                                        handleAgentOutputClick(
                                                            output.id
                                                        )
                                                    }
                                                >
                                                    {output.name}
                                                </span>
                                                <div
                                                    className={
                                                        styles.conversationActions
                                                    }
                                                >
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setRenamingOutputId(
                                                                output.id
                                                            );
                                                            setNewOutputName(
                                                                output.name
                                                            );
                                                        }}
                                                        className={
                                                            styles.renameButton
                                                        }
                                                    >
                                                        ✎
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDeleteOutput(
                                                                output.id
                                                            );
                                                        }}
                                                        className={
                                                            styles.deleteButton
                                                        }
                                                    >
                                                        ×
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className={styles.emptyState}>
                                    No agent outputs available
                                </div>
                            )}
                        </div>
                    </div>
    
                    {/* Viewing Output Modal */}
                    {viewingOutput && (
                        <div className={styles.outputModal}>
                            <div className={styles.outputModalContent}>
                                <button
                                    className={styles.closeButton}
                                    onClick={() => setViewingOutput(null)}
                                >
                                    ×
                                </button>
                                <div className={styles.outputText}>
                                    {viewingOutput}
                                </div>
                            </div>
                        </div>
                    )}
    
                    {/* Confirm Dialog for Conversations */}
                    <ConfirmDialog
                        isOpen={confirmDialog.isOpen}
                        message={confirmDialog.message}
                        onConfirm={handleConfirmDelete}
                        onCancel={() =>
                            setConfirmDialog({
                                isOpen: false,
                                conversationId: '',
                                message: '',
                            })
                        }
                    />
    
                    {/* Confirm Dialog for Agent Outputs */}
                    <ConfirmDialog
                        isOpen={confirmOutputDialog.isOpen}
                        message={confirmOutputDialog.message}
                        onConfirm={() => {
                            if (confirmOutputDialog.outputId) {
                                handleDeleteOutput(confirmOutputDialog.outputId);
                            }
                            setConfirmOutputDialog({
                                isOpen: false,
                                outputId: '',
                                message: '',
                            });
                        }}
                        onCancel={() =>
                            setConfirmOutputDialog({
                                isOpen: false,
                                outputId: '',
                                message: '',
                            })
                        }
                    />
                </div>
            )}
        </div>
    );
};
export default ConversationLibrary;