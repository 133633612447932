import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import XverseOrdinals from './components/argos';
import AllNFTsPage from './components/argogallery';
import ChatComponent from './components/ChatComponent';
import Chat from './components/Chat';
import { Analytics } from '@vercel/analytics/next';
import AiPage from './components/aipage';
import AiPagem from './components/aipagem';




interface AppContentProps {
  children?: React.ReactNode;
}

const AppContent: React.FC<AppContentProps> = ({ children }) => {
  return (
    <Router>
      <div className="App">
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="holders" element={<XverseOrdinals/>}/>
            <Route path="gallery" element={<AllNFTsPage/>}/>
            <Route path="argod2lite" element={<Chat/>}/>
            <Route path="argod2full" element={<ChatComponent/>}/>
            <Route path="argod2" element={<AiPage/>}/>
            <Route path="argod2mobile" element={<AiPagem/>}/>
            <Route path="*" element={children || null} />
            
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default AppContent;