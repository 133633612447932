import React from 'react';
import styles from '../styles/Home.module.css';

const AiPagem: React.FC = () => {
  return (
    <div className={styles.landingContainer2}>
       <div className={styles.imagecontainer}>
      <img className={styles.argoailogo2} src="/argod2logo.png" alt="" />
      </div>
      <h1 className={styles.title2}>Desktop Only. Coming Soon for Mobile!</h1>
     
    </div>
  );
};

export default AiPagem;