import React, { useState, useEffect } from 'react';
import styles from '../styles/Watchlist.module.css';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import PriceHistoryChart from './PriceHistoryChart';

const API_URL = process.env.REACT_APP_API_URL || (process.env.NODE_ENV === 'development' ? 'http://localhost:5008' : 'https://api.argothepuppy.org');



// Add new interfaces for chart data
interface ChartData {
  timestamp: number;
  price: number;
  volume?: number;
}
type ChainType = 'solana' | 'ethereum' | 'base' | 'bsc';
type WatchlistItemType = 'token' | 'nft' | 'ordinal' | 'rune';
type PriceUnit = 'USD' | 'BTC' | 'SOL' | 'ETH';

interface WatchlistItem {
  id: number;
  type: WatchlistItemType;
  name: string;
  address: string;
  chain: ChainType;
  collectionSlug?: string;
  runeSymbol?: string;
  price?: string | number;
  priceUnit?: PriceUnit;
}

interface WatchlistPanelProps {
  onOpenDexScreener: (address: string, chain: string) => void;
  ordinalAddress: string;
}

interface CategoryState {
  [key: string]: boolean;
}

interface PriceData {
  price: string | number;
  unit: PriceUnit;
}

const ME_API_KEY = process.env.REACT_APP_ME_API_KEY;

const WatchlistPanel: React.FC<WatchlistPanelProps> = ({ onOpenDexScreener, ordinalAddress }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [watchlistItems, setWatchlistItems] = useState<WatchlistItem[]>([]);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<WatchlistItem | null>(null);
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [chartLoading, setChartLoading] = useState(false);
  const [categoryStates, setCategoryStates] = useState<CategoryState>({
    token: true,
    nft: true,
    ordinal: true,
    rune: true
  });
  const [newItem, setNewItem] = useState<{
    type: WatchlistItemType;
    name: string;
    address: string;
    chain: ChainType;
    collectionSlug: string;
    runeSymbol: string;
  }>({
    type: 'token',
    name: '',
    address: '',
    chain: 'solana',
    collectionSlug: '',
    runeSymbol: '',
  });

  const [chainStates, setChainStates] = useState<CategoryState>({
    solana: true,
    ethereum: true,
    base: true,
    bsc: true
  });

  const toggleChain = (chain: string) => {
    setChainStates(prev => ({
      ...prev,
      [chain]: !prev[chain]
    }));
  };

  const chainLabels: { [key: string]: string } = {
    solana: 'Solana',
    ethereum: 'Ethereum',
    base: 'Base',
    bsc: 'BSC'
  };



  useEffect(() => {
    const loadWatchlist = async () => {
      if (!ordinalAddress) return;  // Make sure we have the wallet address

      try {
        const response = await fetch(`${API_URL}/api/watchlist/${ordinalAddress}`);
        if (!response.ok) throw new Error('Failed to fetch watchlist');

        const data = await response.json();
        setWatchlistItems(data);
      } catch (error) {
        console.error('Error loading watchlist:', error);
      }
    };

    loadWatchlist();
  }, [ordinalAddress]);

  const formatRuneSymbol = (symbol: string): string => {
    return symbol.trim().toUpperCase().replace(/\s+/g, '•');
  };

  const fetchNFTFloor = async (collectionSlug: string, chain: string): Promise<PriceData> => {
    try {
        let response;

        if (chain === 'solana') {
            response = await fetch(
                `${API_URL}/api/proxy/magiceden/collections/${collectionSlug}/stats`
            );
        } else if (chain === 'ethereum') {
            response = await fetch(
                `${API_URL}/api/proxy/magiceden/ethereum/collections/${collectionSlug}`
            );
        } else {
            throw new Error('Unsupported blockchain');
        }

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        let floorPrice;

        if (chain === 'solana') {
            floorPrice = data.floorPrice / 1000000000;
        } else if (chain === 'ethereum') {
            floorPrice = data.collections[0].floorAsk.price.amount.native;
        }

        return {
            price: floorPrice,
            unit: chain === 'solana' ? 'SOL' : 'ETH'
        };
    } catch (error) {
        console.error('Error fetching NFT floor:', error);
        return { price: 'Error', unit: chain === 'solana' ? 'SOL' : 'ETH' };
    }
};

const fetchRunePrice = async (runeSymbol: string): Promise<PriceData> => {
  try {
      const formattedRuneSymbol = runeSymbol.replace(/•/g, '');
      const response = await fetch(
          `${API_URL}/api/proxy/magiceden/ord/btc/runes/market/${formattedRuneSymbol}/info`
      );

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const floorPrice = data.floorUnitPrice ? parseFloat(data.floorUnitPrice.value) : 0;

      return { price: floorPrice, unit: 'BTC' };
  } catch (error) {
      console.error('Error fetching rune price:', error);
      return { price: 'Error', unit: 'BTC' };
  }
};

  const fetchWithRetry = async (
    fetchFn: () => Promise<PriceData>,
    maxRetries = 3,
    delay = 1000
  ): Promise<PriceData> => {
    for (let i = 0; i < maxRetries; i++) {
      try {
        const result = await fetchFn();
        if (result.price !== 'Rate Limited') {
          return result;
        }
        await new Promise(resolve => setTimeout(resolve, delay * Math.pow(2, i)));
      } catch (error) {
        if (i === maxRetries - 1) throw error;
        await new Promise(resolve => setTimeout(resolve, delay * Math.pow(2, i)));
      }
    }
    return { price: 'Failed after retries', unit: 'BTC' };
  };

  const fetchOrdinalFloor = async (collectionSlug: string): Promise<PriceData> => {
    try {
        const response = await fetch(
            `${API_URL}/api/proxy/magiceden/ord/btc/stat?collectionSymbol=${collectionSlug}`
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return { price: data.floorPrice / 100000000, unit: 'BTC' };
    } catch (error) {
        console.error('Error fetching ordinal floor:', error);
        return { price: 'Error', unit: 'BTC' };
    }
};

const fetchTokenPrice = async (address: string): Promise<PriceData> => {
  try {
    const response = await fetch(
      `https://api.dexscreener.io/latest/dex/tokens/${address}`
    );
    const data = await response.json();
    
    // If no pairs are found, return N/A
    if (!data.pairs || data.pairs.length === 0) {
      return { price: 'N/A', unit: 'USD' };
    }

    // Get the most relevant pair (usually the first one with highest liquidity)
    const relevantPair = data.pairs[0];
    return { price: relevantPair.priceUsd || 'N/A', unit: 'USD' };
  } catch (error) {
    console.error('Error fetching token price:', error);
    return { price: 'Error', unit: 'USD' };
  }
};

useEffect(() => {
  const fetchPrices = async () => {
    if (watchlistItems.length === 0) return;

    const updatedItems = await Promise.all(
      watchlistItems.map(async (item) => {
        try {
          let priceData: PriceData;

          switch (item.type) {
            case 'token':
              // Use address directly for all chains - DexScreener detects the chain automatically
              priceData = await fetchTokenPrice(item.address);
              priceData.unit = 'USD';
              break;
            case 'nft':
              priceData = item.collectionSlug ?
                await fetchNFTFloor(item.collectionSlug, item.chain) :
                { price: 'N/A', unit: item.chain === 'solana' ? 'SOL' : 'ETH' };
              break;
            case 'ordinal':
              priceData = item.collectionSlug ?
                await fetchOrdinalFloor(item.collectionSlug) :
                { price: 'N/A', unit: 'BTC' };
              break;
            case 'rune':
              priceData = item.runeSymbol ?
                await fetchRunePrice(item.runeSymbol) :
                { price: 'N/A', unit: 'BTC' };
              break;
            default:
              priceData = { price: 'N/A', unit: 'USD' };
          }

          return {
            ...item,
            price: priceData.price,
            priceUnit: priceData.unit || 'USD'
          };
        } catch (error) {
          console.error(`Error fetching price for ${item.type}:`, error);
          return {
            ...item,
            price: 'Error',
            priceUnit: (item.type === 'token' ? 'USD' : 'BTC') as PriceUnit
          };
        }
      })
    );

    setWatchlistItems(updatedItems);
  };

    fetchPrices();
    const interval = setInterval(fetchPrices, 30000);
    return () => clearInterval(interval);
  }, [watchlistItems.length]);

  const toggleCategory = (category: string) => {
    setCategoryStates(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  const categorizedItems = {
    token: watchlistItems.filter(item => item.type === 'token'),
    nft: watchlistItems.filter(item => item.type === 'nft'),
    ordinal: watchlistItems.filter(item => item.type === 'ordinal'),
    rune: watchlistItems.filter(item => item.type === 'rune')
  };

  const categoryLabels = {
    token: 'Tokens',
    nft: 'NFTs',
    ordinal: 'Ordinals',
    rune: 'Runes'
  };


  const handleAddItem = async () => {
    console.log("New Item Data:", newItem);

    // Basic validation to ensure required fields are present
    if (!newItem.name || (
      newItem.type === 'token' && !newItem.address
    ) || (
        newItem.type === 'rune' && !newItem.runeSymbol
      ) || (
        newItem.type === 'nft' && !newItem.collectionSlug
      )) {
      return;
    }

    // Create a new watchlist item based on newItem input
    const newWatchlistItem = {
      type: newItem.type,
      name: newItem.name,
      address: newItem.address || '',
      chain: newItem.chain,
      collectionSlug: newItem.collectionSlug || '',
      runeSymbol: newItem.runeSymbol || '',
    };

    try {
      // Send item data to the backend
      const response = await fetch(`${API_URL}/api/watchlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          wallet_address: ordinalAddress,
          ...newWatchlistItem,
        }),
      });

      if (!response.ok) throw new Error('Failed to add watchlist item');

      // Retrieve saved item from backend
      const savedItem = await response.json();
      setWatchlistItems(prev => [...prev, savedItem]);

      // Close the modal and reset the form fields after successful add
      setNewItem({
        type: 'token',
        name: '',
        address: '',
        chain: 'solana',
        collectionSlug: '',
        runeSymbol: '',
      });
      setShowAddModal(false);  // Close the modal after adding
    } catch (error) {
      console.error('Error adding watchlist item:', error);
    }
  };

  const handleRemoveItem = async (id: number) => {
    if (!ordinalAddress) return;

    try {
      const response = await fetch(`${API_URL}/api/watchlist/${ordinalAddress}/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) throw new Error('Failed to delete watchlist item');
      setWatchlistItems(prev => prev.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error removing watchlist item:', error);
    }
  };


  const handleTypeChange = (type: WatchlistItemType) => {
    setNewItem({
      ...newItem,
      type,
      address: '',
      collectionSlug: '',
      runeSymbol: '',
      chain: type === 'nft' ? 'solana' : 'solana',
    });
  };

  const handleItemClick = (item: WatchlistItem) => {
    if (item.type === 'token') {
      onOpenDexScreener(item.address, item.chain);
    } else {
      setSelectedItem(item);
    }
  };

const formatPrice = (item: WatchlistItem): JSX.Element | string => {
    const { price, priceUnit } = item;

    // Handle cases where the price is an error message or N/A
    if (typeof price === 'string' && ['Error', 'N/A'].includes(price)) {
        return price;
    }

    // Ensure price is a number for further formatting
    const priceValue = typeof price === 'number' ? price : Number(price);
    if (isNaN(priceValue)) return 'N/A';

    // Tokens and Runes: Format small prices with leading zeros as subscript
    if (item.type === 'token' || item.type === 'rune') {
        const priceStr = priceValue.toFixed(14); // Consistent 10 decimal places for small values
        const [leading, decimals] = priceStr.split('.');

        if (decimals) {
            const match = decimals.match(/^(0{1,9})(\d+)/);
            if (match) {
                const zeroCount = match[1].length;
                const mainDecimals = match[2].slice(0, 4); // First 4 significant decimals

                // Return JSX with subscript for leading zeros
                return (
                    <>
                        {leading}.0<sub>{zeroCount}</sub>{mainDecimals} {priceUnit}
                    </>
                );
            }
        }
        return `${priceValue.toFixed(4)} ${priceUnit}`; // Default to 4 decimal places if no leading zeros
    }

    // NFTs and Ordinals: Trim trailing zeros
    if (item.type === 'nft' || item.type === 'ordinal') {
        return `${priceValue.toFixed(4).replace(/\.?0+$/, '')} ${priceUnit}`;
    }

    // Default case: Return with two decimal places for other types
    return `${priceValue.toFixed(2)} ${priceUnit}`;
};

const renderCategory = (type: WatchlistItemType) => {
  const items = categorizedItems[type];
  if (items.length === 0) return null;

  return (
    <div key={type} className={styles.categoryContent}>
      <div
        className={styles.categoryHeader}
        onClick={() => toggleCategory(type)}
      >
        <span className={styles.categoryTitle}>
          <span className={`${styles.collapseIcon} ${!categoryStates[type] ? styles.collapsed : ''}`}>
            ▼
          </span>
          {categoryLabels[type]} ({items.length})
        </span>
      </div>

      {categoryStates[type] && (
        <div className={styles.itemsList}>
          {type === 'token' ? (
            renderTokensByChain(items)
          ) : (
            items.map((item) => (
              <div
                key={item.id}
                className={styles.item}
                onClick={() => handleItemClick(item)}
              >
                <div className={styles.itemContent}>
                  <span className={styles.itemName}>{item.name}
                    <button
                      className={styles.removeButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveItem(item.id);
                      }}
                    >
                      ×
                    </button>
                  </span>
                  <span className={styles.itemPrice}>
                    {formatPrice(item)}
                  </span>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

  const renderTokensByChain = (items: WatchlistItem[]) => {
    const tokensByChain = {
      solana: items.filter(item => item.type === 'token' && item.chain === 'solana'),
      ethereum: items.filter(item => item.type === 'token' && item.chain === 'ethereum'),
      base: items.filter(item => item.type === 'token' && item.chain === 'base'),
      bsc: items.filter(item => item.type === 'token' && item.chain === 'bsc')
    };

    return Object.entries(tokensByChain).map(([chain, chainItems]) => {
      if (chainItems.length === 0) return null;

      return (
        <div key={chain} className={styles.categoryHeader2}>
          <div
            className={styles.chainHeader}
            onClick={() => toggleChain(chain)}
          >
            <span className={styles.chainTitle}>
              <span className={`${styles.collapseIcon} ${!chainStates[chain] ? styles.collapsed : ''}`}>
                ▼
              </span>
              {chainLabels[chain]} ({chainItems.length})
            </span>
          </div>

          {chainStates[chain] && (
            <div className={styles.chainItems}>
              {chainItems.map((item) => (
                <div
                  key={item.id}
                  className={styles.item}
                  onClick={() => handleItemClick(item)}
                >
                  <div className={styles.itemContent}>
                    <span className={styles.itemName}>{item.name}
                      <button
                        className={styles.removeButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveItem(item.id);
                        }}
                      >
                        ×
                      </button>
                    </span>
                    <span className={styles.itemPrice}>
                      {formatPrice(item)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className={`${styles.watchlistContainer} ${isExpanded ? styles.expanded : styles.collapsed}`}>
      <button
        className={styles.toggleButton}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? 'CLOSE' : '→'}
      </button>

      {isExpanded && (
        <div className={styles.watchlistContent}>
          <div className={styles.header}>
            <h2 className={styles.title}>Watchlist</h2>
            <button
              className={styles.addButton}
              onClick={() => setShowAddModal(true)}
            >
              Add
            </button>
          </div>

          <div className={styles.itemsList}>
            {renderCategory('token')}
           
          </div>

          {/* Edit out if it doesnt work */}
          {showAddModal && (
            <div className={styles.modal}>
              <div className={styles.modalContent}>
                <h3 className={styles.modalTitle}>Add to Watchlist</h3>

                <div className={styles.formGroup}>
                  <select
                    value={newItem.type}
                    onChange={(e) => handleTypeChange(e.target.value as WatchlistItemType)}
                    className={styles.select}
                  >
                    <option value="token">Token</option>
                    
                  </select>
                </div>

                <div className={styles.formGroup}>
                  <input
                    type="text"
                    placeholder="Name (Can Put Anything)"
                    value={newItem.name}
                    onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                    className={styles.input}
                  />
                </div>

                {newItem.type === 'token' && (
                  <div className={styles.formGroup}>
                    <input
                      type="text"
                      placeholder="Contract Address"
                      value={newItem.address}
                      onChange={(e) => setNewItem({ ...newItem, address: e.target.value })}
                      className={styles.input}
                    />
                  </div>
                )}

                {(newItem.type === 'ordinal' || newItem.type === 'nft') && (
                  <div className={styles.formGroup}>
                    <input
                      type="text"
                      placeholder="Collection Slug"
                      value={newItem.collectionSlug}
                      onChange={(e) => setNewItem({ ...newItem, collectionSlug: e.target.value })}
                      className={styles.input}
                    />
                  </div>
                )}

                {newItem.type === 'rune' && (
                  <div className={styles.formGroup}>
                    <input
                      type="text"
                      placeholder="Rune Symbol (words will be joined with •)"
                      value={newItem.runeSymbol}
                      onChange={(e) => setNewItem({ ...newItem, runeSymbol: e.target.value })}
                      className={styles.input}
                    />
                  </div>
                )}

              {(newItem.type === 'token' || newItem.type === 'nft') && (
              <div className={styles.formGroup}>
                <select
                  value={newItem.chain}
                  onChange={(e) => setNewItem({ ...newItem, chain: e.target.value as ChainType })}
                  className={styles.select}
                >
                  <option value="solana">Solana</option>
                  <option value="ethereum">Ethereum</option>
                  <option value="base">Base</option>
                  <option value="bsc">BSC</option>
                </select>
              </div>
            )}

                <div className={styles.buttonGroup}>
                  <button
                    onClick={() => setShowAddModal(false)}
                    className={`${styles.button} ${styles.buttonCancel}`}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleAddItem}
                    className={`${styles.button} ${styles.buttonSubmit}`}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WatchlistPanel;